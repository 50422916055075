import { CloseDrawerButton } from "../CloseDrawerButton/CloseDrawerButton";
import { SwipeableDrawer as Drawer } from "@mui/material";
import { Thread } from "../../_deprecated/Thread/Thread";
import { drawerStyles } from "../../../styles/styles";
import { selectors } from "../../../redux";
import { useSelector } from "react-redux";
import { ThreadView } from "../../Views/ThreadView/ThreadView";
import { ACTIVE_VIEW_STATE, VIEWPORT_INDEX } from "../../../config";

export const ThreadDrawer = ({ onClose }) => {
  const activeSub = useSelector(selectors.selectActiveSub);
  const activeThread = useSelector(selectors.selectActiveThread);
  const isDesktopMode = useSelector(selectors.selectIsDesktopMode);

  const activeViewState = useSelector(selectors.selectActiveViewState);

  const isActive = activeViewState === ACTIVE_VIEW_STATE.THREAD;
  const zIndex = isActive ? VIEWPORT_INDEX.top : VIEWPORT_INDEX.second;

  const overrides = {
    right: 0,
    width: "calc(100vw - 550px)",
    left: "auto",
    "& .MuiBackdrop-root": {
      right: 0,
      width: "calc(100vw - 550px)",
      left: "auto",
    },
  };

  const enableOverrides = isDesktopMode && !!activeSub;
  const modalProps = enableOverrides
    ? { sx: { ...overrides, zIndex } }
    : { sx: { zIndex } };
  const paperProps = enableOverrides
    ? { sx: { maxWidth: "550px" } }
    : { sx: { width: "100vw" } };
  return (
    <Drawer
      id={"thread-drawer"}
      sx={{ ...drawerStyles }}
      ModalProps={modalProps}
      PaperProps={paperProps}
      open={!!activeThread}
      onClose={onClose}
      onOpen={() => {}}
      anchor="right"
    >
      {isDesktopMode && (
        <CloseDrawerButton placement="left" onClick={onClose} />
      )}
      {/*{activeThread && <Thread post={activeThread} />}*/}
      {activeThread && <ThreadView permalink={activeThread.permalink} />}
    </Drawer>
  );
};
