import { SUBREDDITS } from "./data/subreddits";

export const ALLOWED_SUBS = SUBREDDITS.filter((sub) => !sub?.disabled).map(
  (sub) => sub.name
);

export const STATIC_ASSET_URL = "https://static.anthony.media";
export const isLocalhost = false; // window.location.href.startsWith('http://localhost');

export const BASE_API_URL = isLocalhost
  ? "http://localhost:3000/reddit"
  : "https://api.anthony.media/reddit";

export const THEME_MODES = {
  light: "light",
  dark: "dark",
};

export const ACTIVE_VIEW_STATE = {
  THREAD: "THREAD",
  SUBREDDIT: "SUBREDDIT",
  BROWSE: "BROWSE",
};

export const VIEWPORT_INDEX = {
  top: 1005,
  second: 1004,
  third: 1003,
};

export const CONSTANTS = {
  sortMethodSearch: ["top", "hot", "relevance", "new", "comments"],
  sortMethodSub: ["top", "hot", "rising", "new", "random", "controversial"],
  ignoreSourceDomains: [
    "https://preview.redd.it",
    "https://www.reddit.com",
    "https://i.redd.it",
  ],
  STATIC_SUB_JSON_URL: "https://redcloud.data.anthony.media/data",
};
