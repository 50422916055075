import { applicationRedux } from "../slices/application.slice";

const NAMESPACE = applicationRedux.namespace;

export const selectTheme = (state) => state[NAMESPACE].themeMode;
export const selectIsDarkMode = (state) =>
  state[NAMESPACE].themeMode === "dark";
export const selectActiveSub = (state) => state[NAMESPACE].activeSub;
export const selectActiveThread = (state) => state[NAMESPACE].activeThread;
export const selectIsDesktopMode = (state) => state[NAMESPACE].isDesktopMode;
export const selectHiddenComments = (state) => state[NAMESPACE].hiddenComments;
export const selectPostHistory = (state) => state[NAMESPACE].postHistory;
export const selectCustomThreadModalOpen = (state) =>
  state[NAMESPACE].isCustomThreadModalOpen;

export const selectModalState = (state) => state[NAMESPACE].modalState;

export const selectSubreddits = (state) => state[NAMESPACE].subreddits;

export const selectSubredditData = (state) => state[NAMESPACE].subredditData;

export const selectActiveViewState = (state) =>
  state[NAMESPACE].activeViewState;

export const selectSubredditIsIndexing = (state) =>
  state[NAMESPACE].subredditData.isIndexingSubs;

export const selectSubredditIndexingProgress = (state) =>
  state[NAMESPACE].subredditData.progress;
