import { CloseDrawerButton } from "../CloseDrawerButton/CloseDrawerButton";
import { SwipeableDrawer as Drawer } from "@mui/material";
import { SubredditView } from "../../Views/SubredditView/SubredditView";
import { drawerStyles } from "../../../styles/styles";
import { selectors } from "../../../redux";
import { useSelector } from "react-redux";
import { ACTIVE_VIEW_STATE, VIEWPORT_INDEX } from "../../../config";

export const SubDrawer = ({ onClose, onClickThread }) => {
  const activeSub = useSelector(selectors.selectActiveSub);
  const activeThread = useSelector(selectors.selectActiveThread);
  const isDesktopMode = useSelector(selectors.selectIsDesktopMode);

  const activeViewState = useSelector(selectors.selectActiveViewState);

  const isActive = activeViewState === ACTIVE_VIEW_STATE.SUBREDDIT;
  const zIndex = isActive ? VIEWPORT_INDEX.top : VIEWPORT_INDEX.second;

  const overrides = {
    width: "calc(100vw - 1100px)",
    left: "550px",
    right: "auto",
    "& .MuiBackdrop-root": {
      width: "calc(100vw - 1100px)",
      left: "550px",
      right: "auto",
    },
  };

  const enableOverrides = isDesktopMode && !!activeThread;
  const modalProps = enableOverrides
    ? { sx: { ...overrides, zIndex } }
    : { sx: { zIndex } };
  const paperProps = enableOverrides
    ? { sx: { maxWidth: "550px" } }
    : { sx: { width: "100vw" } };

  /* 
  
  */
  return (
    <Drawer
      id={"sub-drawer"}
      sx={drawerStyles}
      open={!!activeSub}
      ModalProps={modalProps}
      PaperProps={paperProps}
      onClose={onClose}
      anchor="left"
      onOpen={() => {}}
    >
      {isDesktopMode && <CloseDrawerButton onClick={onClose} />}
      <div style={{ width: "100%", height: "100%" }}>
        <SubredditView
          sub={activeSub}
          activeThread={activeThread}
          onClickThread={onClickThread}
        />
      </div>
    </Drawer>
  );
};
