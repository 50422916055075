import {
  createPostHistoryItem,
  getNewPostHistoryArray,
  getUserThemeFromLS,
  saveUserThemeToLS,
} from "../../helpers/functions";

import { ACTIVE_VIEW_STATE, THEME_MODES } from "../../config";
import { createSlice } from "@reduxjs/toolkit";
import { subredditObject } from "../../data/subreddits";

const initialState = {
  isDesktopMode: window.innerWidth >= 500,
  themeMode: getUserThemeFromLS(),
  activeSub: null,
  activeThread: null,
  activeViewState: ACTIVE_VIEW_STATE.BROWSE,
  subreddits: subredditObject,
  subredditData: {
    isIndexingSubs: true,
    currentSubToFetch: null,
    progress: 0.0,
  },
  postHistory: [],
  hiddenComments: [],
  isCustomThreadModalOpen: false,
  modalState: {
    jsonModal: {
      isOpen: false,
      data: null,
    },
    profileModal: {
      isOpen: false,
      data: null,
    },
    awardModal: {
      isOpen: false,
      data: null,
    },
    customThreadModal: {
      isOpen: false,
      data: null,
    },
    websiteModal: {
      isOpen: false,
      data: null,
    },
    subredditModal: {
      isOpen: false,
      data: null,
    },
  },
};

const name = "application";

export const applicationSlice = createSlice({
  name,
  initialState,
  reducers: {
    toggleCustomThreadModal: (state) => {
      state.isCustomThreadModalOpen = !state.isCustomThreadModalOpen;
    },
    toggleTheme: (state) => {
      if (state.themeMode === THEME_MODES.light) {
        state.themeMode = THEME_MODES.dark;
      } else {
        state.themeMode = THEME_MODES.light;
      }
      saveUserThemeToLS(state.themeMode);
    },
    setIsDesktopMode: (state, action) => {
      state.isDesktopMode = action.payload;
    },
    setActiveSub: (state, action) => {
      state.activeSub = action.payload;
      state.activeViewState = ACTIVE_VIEW_STATE.SUBREDDIT;
    },
    setActiveThread: (state, action) => {
      state.activeThread = action.payload;
      state.activeViewState = ACTIVE_VIEW_STATE.THREAD;

      if (action.payload) {
        const item = createPostHistoryItem(action.payload);
        const newHistory = getNewPostHistoryArray(item, state.postHistory);
        state.postHistory = newHistory;
      }
    },
    addToPostHistory: (state, action) => {
      const item = createPostHistoryItem(action.payload);
      state.postHistory.push(item);
    },
    toggleCommentVisibility: (state, action) => {
      const commentId = action.payload;

      if (state.hiddenComments.includes(commentId)) {
        state.hiddenComments = state.hiddenComments.filter(
          (id) => id != commentId
        );
        return;
      } else {
        state.hiddenComments.push(commentId);
      }
    },
    updateModal: (state, { payload }) => {
      const { modalId, data, isOpen } = payload;
      if (isOpen === true || isOpen === false) {
        state.modalState[modalId].isOpen = isOpen;
      }
      if (data) {
        state.modalState[modalId].data = data;
      }
    },
    closeAllModals: (state) => {
      for (const modalId in state.modalState) {
        state.modalState[modalId].isOpen = false;
      }
    },
    setCurrentSubToFetch: (state, action) => {
      state["subredditData"].currentSubToFetch = action.payload;
    },
    setIsIndexingSubs: (state, action) => {
      state["subredditData"].isIndexingSubs = action.payload;
    },
    setIndexingProgress: (state, action) => {
      state["subredditData"].progress = action.payload;
    },
    setActiveViewState: (state, action) => {
      const { payload } = action;
      state.activeViewState = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setActiveSub,
  setActiveThread,
  setIsDesktopMode,
  addToPostHistory,
  toggleCommentVisibility,
  toggleTheme,
  toggleCustomThreadModal,
  updateModal,
  closeAllModals,
  setCurrentSubToFetch,
  setIsIndexingSubs,
} = applicationSlice.actions;

export const applicationRedux = {
  actions: applicationSlice.actions,
  reducer: applicationSlice.reducer,
  namespace: name,
};
export default applicationSlice.reducer;
